import { toast } from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { clinicEndpoints } from "../apis";

const { GET_ALL_REFEERED_CLINIC_API } = clinicEndpoints;

export const getAllReferredClinics = async (token) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      GET_ALL_REFEERED_CLINIC_API,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (!response?.status === 200) {
      throw new Error(response?.data?.message);
    }

    const allReferredClinicDetails = response?.data?.AllReferredClinicDetails || [];
    result = allReferredClinicDetails.flatMap(user => user.clinicInvitations);
  } catch (error) {
    console.log("GET_ALL_REFEERED_CLINIC_API API ERROR............", error);
    toast.error(error.message);
  }
  toast.dismiss(toastId);
  return result;
};