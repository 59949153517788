import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import Pagination from "../../common/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getAllReferredClinics } from "../../../services/operations/clinicAPI";

function ClinicManagement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [invitedClinics, setInvitedClinics] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredClinics = invitedClinics?.filter((user) => {
    return user?.Name?.toLowerCase().includes(searchTerm.toLowerCase());
  });
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentClinics = filteredClinics?.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    // Calling fetchCourseDetails fucntion to fetch the details
    (async () => {
      try {
        const res = await getAllReferredClinics(token);
        setInvitedClinics(res);
      } catch (error) {
        console.log("Could not fetch Course Details");
      }
    })();
  }, []);

  return (
    <div className="">
      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          Invited Clinic Data
        </div>
      </div>

      {/* Search Bar */}
      <label className="w-full mx-auto flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2 mt-6">
        <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
        <input
          type="text"
          placeholder="Search for Clinics"
          value={searchTerm}
          onChange={handleSearchChange}
          className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
        />
      </label>

      <div className="w-full mx-auto mt-4 shadow rounded-md">
        <table className="min-w-full">
          <thead>
            <tr className="text-left bg-gray-50 border-b border-gray-200">
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                ID
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Name
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Email
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Phone
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                City
              </th>
            </tr>
          </thead>
          <tbody>
            {currentClinics?.map((user) => (
              <tr
                key={user.ACIID}
                className="border-b border-gray-200 hover:bg-sky-50"
              >
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {user.ACIID}
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {user.Name}
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {user.Email}
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {user.Phone}
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {user.City || "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        totalItems={filteredClinics?.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default ClinicManagement;
