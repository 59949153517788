import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import logo from "../assets/images/logo.svg";
import background from "../assets/images/background.jpeg";
import { login } from "../services/operations/authAPI";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    Email: "",
    Password: "",
  });
  const [inputErrors, setInputErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!formData.Email) errors.Email = "Email is required";
    if (!formData.Password) errors.Password = "Password is required";

    if (Object.keys(errors).length > 0) {
      setInputErrors(errors);
      toast.error("Please fill all the fields");
      return;
    }
    dispatch(login(formData.Email, formData.Password, navigate));
    // navigate("/dashboard/admin-dashboard");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setInputErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  return (
    <div
      className="min-h-screen w-full flex items-center justify-center p-4"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="bg-white rounded-xl shadow-xl p-8 w-full max-w-md">
        <img src={logo} alt="Company Logo" />
        <h1 className="text-zinc-900 text-2xl font-medium leading-relaxed mt-2 mb-6">
          Welcome Back
        </h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email Address
            </label>
            <input
              type="email"
              name="Email"
              value={formData.Email}
              onChange={handleChange}
              className={`w-full px-4 py-2 border ${
                inputErrors.Email ? "border-red-500" : "border-gray-300"
              } rounded-md focus:ring-1 focus:ring-sky-500 focus:border-sky-500 outline-none transition-colors`}
              placeholder="Enter your email"
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="Password"
                value={formData.Password}
                onChange={handleChange}
                className={`w-full px-4 py-2 border ${
                  inputErrors.Password ? "border-red-500" : "border-gray-300"
                } rounded-md focus:ring-1 focus:ring-sky-500 focus:border-sky-500 outline-none transition-colors`}
                placeholder="Enter your password"
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEye size={20} />
                ) : (
                  <AiOutlineEyeInvisible size={20} />
                )}
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="w-full shadow-md shadow-sky-300 bg-sky-500 text-white py-2 px-4 rounded-md focus:outline-none focus:ring-1 focus:ring-sky-400 focus:ring-offset-2 transition-colors"
          >
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
