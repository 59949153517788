import { useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineShoppingCart } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link, matchPath, useLocation } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import { NavbarLinks } from "../../data/navbar-links";
import { apiConnector } from "../../services/apiconnector";
import { categories } from "../../services/apis";
import { ACCOUNT_TYPE } from "../../utils/constants";
import ProfileDropdown from "../core/Auth/ProfileDropDown";

function Navbar() {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const location = useLocation();

  const [subLinks, setSubLinks] = useState([]);

  const matchRoute = (route) => {
    return matchPath({ path: route }, location.pathname);
  };

  return (
    <div className={`h-14 border-b flex items-center justify-center`}>
      <div className="flex w-11/12 items-center justify-between">
        <img src={logo} className="h-12 w-auto object-contain" />
        <div className="flex flex-row justify-center items-center">
          <img
            src={user.Image}
            alt="PatientPic"
            className="w-10 h-10 rounded-full object-cover mr-5"
          />
          <div className="flex flex-col justify-center items-start">
            <h1 className="text-black text-sm font-bold font-poppins">
              {user?.FirstName + " " + user?.LastName}
            </h1>
            <h1 className="text-zinc-400 text-xs font-regular font-poppins">
              Admin user
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
