import { toast } from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { setLoading, setPatients } from "../../slices/patientSlice";
import { getBucketURL } from "../../utils/s3BucketUrl";
import { patientEndpoints } from "../apis";

const { GET_ALL_PATIENTS_API } = patientEndpoints;

export function getAllPatients(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_PATIENTS_API, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const patient = response?.data?.Users.map(async (patient) => {
        const Image = await getBucketURL(patient?.ProfileImageUrl);
        patient.Image = Image;
        return patient;
      });

      const updatedPatientList = await Promise.all(patient);
      dispatch(setPatients(updatedPatientList));
    } catch (error) {
      console.log("GET_ALL_PATIENTS_API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}
