import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  patients: [],
};

const patientSlice = createSlice({
  name: "patient",
  initialState: initialState,
  reducers: {
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setPatients(state, value) {
      state.patients = value.payload;
    },
  },
});

export const { setLoading, setPatients } = patientSlice.actions;

export default patientSlice.reducer;
