import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function ManageSubscription() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {};

  console.log("====================================");
  console.log("data", data);
  console.log("====================================");
  return (
    <div>
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Manage Subscription Plan for Organization
      </div>

      {/* Organization Card */}
      <div className="max-w-xs bg-white rounded-xl shadow mt-14 p-3">
        <div className="flex row items-center space-x-4">
          <img
            src={data?.Image}
            alt="PatientPic"
            className="w-24 h-24 shadow rounded-full object-contain"
          />
          <div>
            <div className="text-black text-base font-semibold font-poppins">
              {data?.Clinic}
            </div>
            <div className="text-zinc-500 text-base font-medium font-poppins">
              ID: {data?.ORGID}
            </div>
          </div>
        </div>
      </div>

      <div className="text-black text-base font-medium font-poppins my-4">
        Current Plan Details:
      </div>

      <div className="bg-white rounded-xl shadow p-4">
        <div className="flex flex-row space-x-10">
          <div className="flex-1 space-y-10">
            <div className="flex flex-row items-center">
              <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                Subscription Plan Name:
              </div>
              <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                {data?.subscription?.clinicianSubscription?.PlanName}
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                Included Features:
              </div>
              <div className="flex flex-wrap gap-2 w-1/2">
                {data?.subscription?.clinicianSubscription?.Perks.map(
                  (perk) => (
                    <div
                      key={perk.PerkID}
                      className="px-4 py-2 bg-sky-100 rounded-3xl"
                    >
                      <div className="text-sky-700 text-xs font-medium font-poppins">
                        {perk.Perks}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                Start Date:
              </div>
              <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                {moment(data?.subscription?.SubscriptionStartDate).format(
                  "DD/MM/YYYY"
                )}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                Billing Cycle:
              </div>
              <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                {data?.subscription?.clinicianSubscription?.PlanCycle}
              </div>
            </div>
          </div>

          <div className="flex-1 space-y-10">
            <div className="flex flex-row items-center">
              <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                Subscription Plan ID:
              </div>
              <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                {data?.subscription?.SubscriptionID}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                Plan Status:
              </div>
              <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                {data?.subscription?.clinicianSubscription?.Status}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                End Date:
              </div>
              <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                {moment(data?.subscription?.SubscriptionEndDate).format(
                  "DD/MM/YYYY"
                )}
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex-1 text-neutral-600 text-base font-medium font-poppins">
                Plan Price:
              </div>
              <div className="flex-1 text-zinc-500 text-base font-medium font-poppins">
                {parseInt(data?.subscription?.clinicianSubscription?.PlanPrice)}{" "}
                CHF
              </div>
            </div>

            <button
              onClick={() =>
                navigate("subscription-plans", {
                  state: { data: data, mode: "change" },
                })
              }
              className="px-10 py-3.5 bg-white rounded-3xl shadow border border-amber-500 flex items-center space-x-3 hover:scale-105 hover:shadow-lg hover:shadow-amber-200 transition-transform duration-200 active:scale-95"
            >
              <div className="text-black text-sm font-semibold font-poppins">
                Change Subscription Plan
              </div>
              <ArrowLongRightIcon className="h-8 text-black text-sm font-semibold" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageSubscription;

const PERKS = [
  {
    id: 1,
    name: "Perk 1",
  },
  {
    id: 2,
    name: "Perk 2",
  },
  {
    id: 3,
    name: "Perk 3",
  },
  {
    id: 4,
    name: "Perk 4",
  },
  {
    id: 5,
    name: "Perk 5",
  },
];
