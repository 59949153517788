import { toast } from "react-hot-toast";
import { apiConnector } from "../apiconnector";
import { getBucketURL } from "../../utils/s3BucketUrl";
import { subscriptionEndpoints } from "../apis";
import {
  setOrganization,
  setPerks,
  setPlans,
  setLoading,
} from "../../slices/clinicanOrganizationSlice";

const {
  GET_ALL_ORGANIZATION_SUBSCRIPTIONS_API,
  GET_ALL_SUBSCRIPTION_PLANS_API,
  GET_ALL_PERKS_API,
  ADD_SUBSCRIPTION_PLAN_API,
  UPDATE_SUBSCRIPTION_PLAN_API,
  DELETE_SUBSCRIPTION_PLAN_API,
  ASSIGN_SUBSCRIPTION_PLAN_API,
} = subscriptionEndpoints;

// GET ALL ORGANIZATION SUBSCRIPTIONS
export function getAllOrganizationSubscriptions(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_ORGANIZATION_SUBSCRIPTIONS_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      const clinic = response?.data?.data?.map(async (org) => {
        const Image = await getBucketURL(org?.Profile_Image);
        org.Image = Image;
        return org;
      });

      const updatedClinicList = await Promise.all(clinic);
      dispatch(setOrganization(updatedClinicList));
    } catch (error) {
      console.log(
        "GET_ALL_ORGANIZATION_SUBSCRIPTIONS_API ERROR............",
        error
      );
    }
    dispatch(setLoading(false));
  };
}

// GET ALL SUSCRIPTION PLANS
export function getAllSubscriptionPlans(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_SUBSCRIPTION_PLANS_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setPlans(response?.data?.data));
    } catch (error) {
      console.log("GET_ALL_SUBSCRIPTION_PLANS_API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}

// GET ALL PERKS
export function getAllPerks(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_PERKS_API, null, {
        Authorization: `Bearer ${token}`,
      });

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setPerks(response?.data?.perks));
    } catch (error) {
      console.log("GET_ALL_PERKS_API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}

// ADD SUBSCRIPTION PLAN
export function addSubscriptionPlan(token, body, navigate) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "POST",
        ADD_SUBSCRIPTION_PLAN_API,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.status !== 201) {
        throw new Error(response?.data?.message);
      }

      toast.success("Subscription Plan Added Successfully");
      dispatch(getAllSubscriptionPlans(token));
      navigate(-1);
    } catch (error) {
      console.log("ADD_SUBSCRIPTION_PLAN_API ERROR............", error);
      toast.error(error?.message);
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// UPDATE SUBSCRIPTION PLAN
export function updateSubscriptionPlan(token, ID, data, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_SUBSCRIPTION_PLAN_API + "/" + ID,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("Subscription Plan Updated Successfully");
      dispatch(getAllSubscriptionPlans(token));
      navigate(-1);
    } catch (error) {
      console.log("UPDATE_SUBSCRIPTION_PLAN_API ERROR............", error);
      toast.error(error?.message);
    }
    toast.dismiss(toastId);
  };
}

// DELETE SUBSCRIPTION PLAN
export function deleteSubscriptionPlan(token, id) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_SUBSCRIPTION_PLAN_API + "/" + id,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("Subscription Plan Deleted Successfully");
      dispatch(getAllSubscriptionPlans(token));
    } catch (error) {
      console.log("DELETE_SUBSCRIPTION_PLAN_API ERROR............", error);
      toast.error(error?.message);
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ASSIGN SUBSCRIPTION PLAN
export function assignSubscriptionPlan(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "POST",
        ASSIGN_SUBSCRIPTION_PLAN_API,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }

      toast.success("Subscription Plan Assigned Successfully");
      dispatch(getAllOrganizationSubscriptions(token));
      navigate(-1);
    } catch (error) {
      console.log("ASSIGN_SUBSCRIPTION_PLAN_API ERROR............", error);
      toast.error(error?.message);
    }
    toast.dismiss(toastId);
  };
}
