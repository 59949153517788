import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  organization: [],
  plans: [],
  perks: [],
};

const clinicianOrganizationSlice = createSlice({
  name: "clinicianOrganization",
  initialState: initialState,
  reducers: {
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setOrganization(state, value) {
      state.organization = value.payload;
    },
    setPlans(state, value) {
      state.plans = value.payload;
    },
    setPerks(state, value) {
      state.perks = value.payload;
    },
  },
});

export const { setLoading, setOrganization, setPlans, setPerks } =
  clinicianOrganizationSlice.actions;

export default clinicianOrganizationSlice.reducer;
