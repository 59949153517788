import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import Sidebar from "../components/core/Dashboard/Sidebar";
import { useEffect } from "react";
import { getAllPatients } from "../services/operations/patientAPI";
import { getAllOrganizationSubscriptions, getAllPerks, getAllSubscriptionPlans } from "../services/operations/clinicianOrganizationAPI";

function Dashboard() {
  const dispatch = useDispatch();
  const { loading: profileLoading } = useSelector((state) => state.profile);
  const { token, loading: authLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      dispatch(getAllPatients(token));
      dispatch(getAllOrganizationSubscriptions(token));
      dispatch(getAllSubscriptionPlans(token));
      dispatch(getAllPerks(token));
    }
  }, []);

  if (profileLoading || authLoading) {
    return (
      <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="relative flex min-h-[calc(100vh-3.5rem)]">
      <Sidebar />
      <div className="h-[calc(100vh-3.5rem)] flex-1 overflow-auto">
        <div className="mx-auto w-11/12 max-w-maxContent py-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
