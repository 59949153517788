import { ACCOUNT_TYPE } from "../utils/constants";
export const sidebarLinks = [
  {
    id: 1,
    name: "Dashboard",
    path: "admin-dashboard",
    icon: "RxDashboard",
  },
  {
    id: 2,
    name: "Patient Management",
    path: "user-management",
    icon: "RxDashboard",
  },
  {
    id: 3,
    name: "Clinic Management",
    path: "/clinic-management",
    icon: "RxDashboard",
  },
  {
    id: 4,
    name: "Subscription Management",
    path: "subscription-management",
    icon: "RxDashboard",
  },
];
