import React from "react";

function Admin() {
  return (
    <div>
      {/* Header */}
      <div className="flex flex-row items-center space-x-1">
        <h1 className="text-zinc-900 text-2xl font-medium font-poppins">
          Hi Admin,
        </h1>
        <p className="text-2xl animate-bounce mt-2">👋</p>
      </div>
    </div>
  );
}

export default Admin;
