import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OpenRoute from "./components/core/Auth/OpenRoute";
import PrivateRoute from "./components/core/Auth/PrivateRoute";
import Login from "./pages/Login";

import Dashboard from "./pages/Dashboard";
import Admin from "./components/core/Dashboard/AdminDashboard/Admin";
import Navbar from "./components/common/Navbar";
import MyProfile from "./components/core/Dashboard/MyProfile";
import PatientManagement from "./components/core/PatientManagement/PatientManagement";
import ClinicManagement from "./components/core/ClinicManagement/ClinicManagement";
import SubscriptionManagement from "./components/core/SubscriptionManagement/SubscriptionManagement";
import ManageSubscriptionPlan from "./components/core/SubscriptionManagement/ManageSubscriptionPlan";
import AddSubscriptionPlan from "./components/core/SubscriptionManagement/AddSubscriptionPlan";
import ManageSubscription from "./components/core/SubscriptionManagement/ManageSubscription";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.profile);
  return (
    <div className="w-screen min-h-screen bg-white flex flex-col font-poppins">
      {user && <Navbar />}
      <Routes>
        <Route
          path="/"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />
        <Route
          path="login"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />

        <Route
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route path="admin-dashboard" element={<Admin />} />
          <Route path="user-management" element={<PatientManagement />} />
          <Route path="clinic-management" element={<ClinicManagement />} />
          <Route path="subscription-management">
            <Route index element={<SubscriptionManagement />} />
            <Route path="subscription-plans">
              <Route index element={<ManageSubscriptionPlan />} />
              <Route
                path="add-subscription-plans"
                element={<AddSubscriptionPlan />}
              />
            </Route>
            <Route
              path="add-subscription-plans"
              element={<AddSubscriptionPlan />}
            />
            <Route path="manage-subscription">
              <Route index element={<ManageSubscription />} />
              <Route path="subscription-plans">
                <Route index element={<ManageSubscriptionPlan />} />
                <Route
                  path="add-subscription-plans"
                  element={<AddSubscriptionPlan />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
