import { useRef, useState } from "react";
import { IoFilterSharp } from "react-icons/io5";
import useOnClickOutside from "../../hooks/useOnClickOutside";

export default function FilterDropdown({ onFilterChange }) {
  const [open, setOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All"); // Add this line
  const ref = useRef(null);

  useOnClickOutside(ref, () => setOpen(false));

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter); // Add this line
    onFilterChange(filter === "All" ? null : filter);
    setOpen(false);
  };

  return (
    <button onClick={() => setOpen(true)} className="relative">
      <div className="px-3 py-2 bg-white rounded shadow border border-sky-500 justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95">
        <IoFilterSharp className="text-sky-500 text-md font-bold" />
        <div className="text-sky-500 text-sm font-normal font-poppins">
          Filters
        </div>
      </div>
      {open && (
        <div
          onClick={(e) => e.stopPropagation()}
          className="absolute top-10 right-0 w-40 bg-white border border-gray-200 rounded shadow-2xl p-4 z-50 overflow-hidden"
          ref={ref}
        >
          <div className="">
            {filterOptions.map((option) => (
              <div
                key={option.id}
                onClick={() => handleFilterClick(option.name)}
                className={`cursor-pointer text-left text-gray-800 text-xs font-normal font-poppins rounded p-2 hover:bg-sky-100 hover:text-sky-500 ${
                  selectedFilter === option.name
                    ? "text-sky-500"
                    : ""
                }`}
              >
                <span>{option.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </button>
  );
}

const filterOptions = [
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Yearly",
  },
  {
    id: 2,
    name: "Monthly",
  },
  {
    id: 3,
    name: "Custom Enterprise",
  },
];
